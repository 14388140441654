body {
  padding-top: 70px;
}

.navbar-inverse .navbar-nav > .active > a,
.navbar-inverse .navbar-nav > .active > a:hover,
.navbar-inverse .navbar-nav > .active > a:focus {
  background-color: #ffb22c;
  color: black;
}
.navbar-inverse .navbar-nav > li > a {
  color: #bbb;
}
.navbar-inverse .navbar-brand {
  font-size: 14px;
}
.navbar-inverse .navbar-brand span {
  color: #ffb22c;
  font-size: 30px;
}
.navbar-nav > li > .dropdown-menu {
  left: 0;
  right: auto;
}
.dropdown-menu {
  background-color: #fafafa;
  border-top: 3px solid #ffb22c;
}
.dropdown-menu li > a {
  color: #141212;
  padding: 6px 20px;
  /* -webkit-transition: padding 0.5s ease;
  -moz-transition: padding 0.5s ease;
  -o-transition: padding 1s ease;
  -transition-transition: padding 0.5s ease; */
  /* -webkit-transition: padding 0.1s ease;
  -moz-transition: padding 1s ease;
  -o-transition: padding 1s ease;
  transition: padding 1s ease; */
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.dropdown-menu li > a:hover,
.dropdown-menu li > a:focus {
  background-color: #ffb22c;
  color: #141212;
}
.dropdown-menu .divider {
  background-color: #ffb22c;
}
.container > .navbar-header,
.container-fluid > .navbar-header,
.container > .navbar-collapse,
.container-fluid > .navbar-collapse {
  padding-bottom: 0;
}
/* carousel */
.myslide {
  padding-top: 0;
}

.carousel-caption {
  bottom: 188px;
  background: rgba(3, 3, 3, 0.4);
  padding-right: 20px;
  padding-left: 20px;
}
.carousel-indicators {
  bottom: 5px;
}
.carousel-indicators li {
  height: 15px;
  width: 15px;
  background-color: #6e6e6e;
}
.carousel-indicators .active {
  height: 22px;
  width: 22px;
  background-color: #ffb22c;
}
/* about */
.about {
  padding-top: 60px;
  padding-bottom: 60px;
}
.about .lead {
  color: #888;
  font-size: 25px;
  margin-top: 15px;
}
.about h1 span {
  color: #ffb22c;
}
.about h1 {
  font-weight: bold;
}
.about h3 span {
  color: #ffb22c;
}
/* about */

/* start features  */
.features {
  background: #eee;
}
.features .feat {
  margin-bottom: 30px;
}
p {
  line-height: 2em;
}
.features h1 {
  padding-bottom: 50px;
  padding: 50px;
  font-weight: bold;
}
.features .feat span.glyphicon {
  font-size: 24px;
  margin-bottom: 10px;
  color: #ffb22c;
}
/*  start features  */

/* testmonual */
.testimonials {
  padding-top: 50px;
  padding-bottom: 50px;
}
.testimonials h1 {
  font-weight: bold;
  margin-bottom: 20px;
}
.testimonials.lead {
  line-height: 2em;
  margin-bottom: 10px;
}
.testimonials span {
  font-size: 18px;
  font-weight: bold;
  color: #ffb22c;
}
.testimonials .carousel-inner {
  min-height: 150px;
}
.testimonials .carousel-indicators {
  position: static;
  width: 100%;
  margin-left: auto;
  padding-top: 20px;
}
.testimonials .carousel-indicators li {
  text-indent: 0%;
  width: 80px;
  height: 80px;
  opacity: 0.5;
  border: 2px solid #fff;
  border-radius: 50%;
  overflow: hidden;
}
.testimonials .carousel-indicators li.active {
  opacity: 1;
  border: 2px solid #ffb22c;
}

.testimonials .carousel-indicators li img {
  max-width: 100%;
}
/* testmonual */

/* helper */
.helper {
  padding-top: 40px;
  padding-bottom: 40px;
  background: #eee;
}
.helper .h1 {
  margin-bottom: 40px;
}
.helper .helpers {
  background: #fff;
  padding: 10px;
  border: 1px solid #ddd;
}
.helper .helpers h2 {
  font-size: 20px;
  font-weight: bold;
  color: #666;
}
.helper .helpers p {
  margin: 5px 0 5px;
  font-size: medium;
}
.helper .helpers a {
  margin-bottom: 5px;
}

/* helper */
/* statstic */
.statstic {
  background: url("../images/bg-callout.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  min-height: 300px;
}
.statstic .data {
  background-color: rgba(41, 40, 40, 0.7);
  color: #b6b5b5;
  min-height: 300px;
  padding-top: 40px;
  padding-bottom: 70px;
}
.statstic h1 {
  margin-bottom: 60px;
}
.statstic p {
  font-size: 60px;
  font-weight: 800;
  margin-bottom: 0;
}
/* statstic */
/* fotter */
.footer {
  background: #111;
  color: #aaa;
  padding-top: 20px;
  /* padding-bottom: 20px; */
}
.footer a {
  color: #aaa;
}
.footer a:haver {
  color: #fff;
}
.footer h3 {
  color: #ffb22c;
  letter-spacing: 1px;
}
.footer .social-list {
  overflow: hidden;
  margin: 20px 0 10px;
}
.footer .social-list li {
  float: left;
  margin-right: 4px;
}
.footer .copyright {
  background: #333;
  color: #aaa;
  padding: 15px 0;
  margin-top: 20px;
}
.footer .copyright span {
  color: #ffb22c;
}
/* footer */

/* contactus */
.contactus {
  background: url("../images/ncon.png") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  min-height: 300px;
}
.contactus .fields {
  background: rgba(241, 238, 206, 0.2);
  min-height: 300px;
  color: #333;
  padding-top: 40px;
  padding-bottom: 40px;
}
.contactus p {
  margin-bottom: 40px;
}
.contactus textarea {
  height: 108px;
  padding-bottom: 30px;
  margin-top: -10px;
  width: 100%;
}
.contactus button {
  margin-top: 80px;
  width: 100%;
}

/* contactus */
